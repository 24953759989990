import React from 'react'
import { Card, Col } from 'react-bootstrap';
import "./WhyUs.scss";

export const WhyUs = () => {
    let data = [
        {
            name: "Craftsmanship",
            description: "We sweat the small stuff, because details matter. To be a premium brand, every touch point matters and we leave none to chance.",
        },
        {
            name: "Happy Customers",
            description: "Every buyer has a unique story, and every space, be it commercial or residential reflects these stories. And we are delighted to create them.",
        },
        {
            name: "Timely Completion",
            description: "We have a reputation of delivering projects on time every time. We keep our clients in the loop across every stage of the construction process right from booking to possession.",
        },
        {
            name: "Trust & Integrity",
            description: "We understand that buying a home is a once in a lifetime decision. Transparency throughout the buying process has helped us build a strong relationship of trust with our customers.",
        },
        {
            name: "Compliant with Regulations",
            description: "We have zero tolerance policy for violation of rules & regulations and safeguard the rights of our customer by following the guidelines to the minutest detail.",
        },
        {
            name: "Experience & Expertise",
            description: "Our rich experience in the real estate sector lends us an edge over others and eventually benefits our customers and stakeholders to take the right decision at the right time.",
        },
    ]
    return (
        <section id="section-why-us">
            <div className='container-fluid'>
                <div>
                <h3 className='text-center mx-auto p-2 bg-white' style={{width:"max-content", textAlign:"center"}}>
                    Our Strength & Values
                </h3>
                </div>
                <div className="row mx-5 mt-2" style={{zIndex:"1", position:"relative"}}>
                    {data.map((item) => {
                        return (
                            <Col md={4} lg={4} sm={12} xs={12}>
                                <Card className='my-2 text-center'>
                                    <Card.Body className='d-flex justify-content-center align-items-center flex-column'>
                                        <h5>{item.name}</h5>
                                        <p>{item.description}</p>
                                    </Card.Body>
                                </Card>
                            </Col>
                        )
                    })}
                </div>
            </div>
        </section>
    )
}
