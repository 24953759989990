import React from 'react'

import "./our-projects.scss";
import { ProjectList } from './components/ProjectList';
import { projectData } from '../../data';
export const OurProjects = () => {
    return (
        <section id="our-projects">
            <div className='container-fluid'>
                <div className='row mb-4'>
                    <div className='col-12 col-md-6 text-center p-2 p-md-4 left'>
                        <h3 className='mb-4'>Crafting Your Unique Sanctuary</h3>
                        <p className='mt-4'>Kailasa Group creates unique homes that reflect individual client aspirations, blending innovative design with functionality. Our commitment to quality and craftsmanship results in inviting, durable living spaces that enhance lifestyles, making every home a true sanctuary.</p>
                    </div>
                    <div className='col-12 col-md-6 text-center p-4'>
                        <h3 className='mb-4'>Focused on You</h3>
                        <p className='mt-4'>At Kailasa Group, exceptional customer service is our cornerstone. We prioritize clear communication, actively listening to our clients' needs and preferences. Our dedicated team guides homeowners through every step of the process, ensuring a seamless experience.</p>
                    </div>
                </div>
                <div className='row text-center mb-4'>
                    <div className='col-12'>
                        <h2>Our projects</h2>
                    </div>
                    <div className='col-12'>
                        There’s no place like a Kailasa home. Our projects are created with a lot of heart and<br /> we always aspire to outdo ourselves in delighting you. Discover the space that’s right for you.

                    </div>
                </div>
                <ProjectList data={projectData} />
            </div>
        </section>
    )
}
