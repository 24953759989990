import React from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Home } from './pages/Home/Home';
import { Header } from './shared/Header/Header';
import { OurProjects } from './pages/OurProjects/OurProjects';
import { WhyUs } from './pages/WhyUs/WhyUs';

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<React.Fragment>
          <Home />
          <OurProjects />
        </React.Fragment>} />
        <Route path="/why-us" element={<WhyUs />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
