import React, { useState } from 'react'
import "./header.scss";
import { SidebarDrawer } from '../SidebarDrawer/SidebarDrawer';

export const Header = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
      <div id="header" className='px-3'>
        <div className='sidebar d-flex align-items-center'>
          <img src="/assets/images/sidebar-menu.png" alt="sidebar menu" height={50} onClick={handleShow} className="sidebar-menu bg-transparent me-3" />
          <h4 className='d-none d-md-block d-lg-block ms-3 mt-2'>A Journey into (Un)Real Estate...</h4>
        </div>
        <div onClick={() => window.location.href.includes("why-us") ? window.location.href = "/" : window.scrollTo(0, 0, "smooth")}>
          <img src="/assets/images/kailasa_group_logo.svg" height={150} width={150} alt='Kailasa_logo' />
        </div>
      <SidebarDrawer show={show} handleClose={handleClose} />
      </div>
  )
}
