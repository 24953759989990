
import React from 'react'
import { Card } from 'react-bootstrap'

import "./ProjectItem.scss";
export const ProjectItem = (props) => {
  return (
    <Card className='cursor-pointer project-item mx-auto' onClick={() => window.open(props.item.fileUrl, "_blank")}>
      <Card.Body className='p-0 d-flex justify-content-center align-items-center'>
        <img src={props.item.logo} alt={props.item.name} />
      </Card.Body>
    </Card>
  )
}
