import React from 'react'
import "./home.scss";
import Slider from 'react-slick';

export const Home = () => {

    let imageData = [
        "/assets/images/kailasa-bg-1.jpg",
        "/assets/images/kailasa-bg-2.jpg",
        "/assets/images/kailasa-bg-3.jpg"
    ]

    return (
        <section id="home" className='home-section'>
            <Slider
                dots={false}
                fade={true}
                autoplay={true}
                infinite={true}
                speed={1500}
                adaptiveHeight={true}
                slidesToShow={1}
                slidesToScroll={1}
            >
                {imageData.map((image) => {
                    return <div>
                        <img className='h-100 w-100' src={image} alt={"img"} />
                    </div>
                })}
            </Slider>
        </section>
    )
}
