import React from 'react'

import { ProjectItem } from './ProjectItem';

export const ProjectList = (props) => {
    return (
        props.data.map((project) => {
            return <div className='col-12 col-md-12 text-center mx-md-auto mx-lg-auto px-0'><ProjectItem item={project} /></div>
        }
        ))

}
