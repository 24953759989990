import React from 'react'
import { Accordion, Offcanvas } from 'react-bootstrap';
import "./SidebarDrawer.scss";
import { ProjectList } from '../../pages/OurProjects/components/ProjectList';
import { projectData } from '../../data';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const SidebarDrawer = ({ show, handleClose }) => {
    const sidebarData = [
        {
            title: "Home",
            navigationUrl: "/"
        },
        {
            title: "Why us",
            navigationUrl: "/why-us"
        },
        {
            title: "Our Projects",
            formatter: () => {
                return (
                    <Accordion id='sidebar-accordion' className='my-3'>
                        <Accordion.Item eventKey='1'>
                            <Accordion.Header>Our Projects</Accordion.Header>
                            <Accordion.Body>
                                {projectData.map((project) => {
                                    return <span className="sidebar-project-items" onClick={() => window.open(project.fileUrl, "_blank")} key={project.name}>{project.name}</span>
                                })}
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                )
            }
        },
        {
            title: "Contact Us",
            formatter: () => {
                return (
                    <Accordion id='sidebar-accordion' className='my-3'>
                        <Accordion.Item eventKey='2'>
                            <Accordion.Header>Contact Us</Accordion.Header>
                            <Accordion.Body>
                                <div className='my-1'>
                                    <img src="/assets/images/phone.svg" height={15} className='me-2' alt="phone" />: +91 - 63 53 83 2208
                                </div>
                                <div className='my-1'>
                                    <img src="/assets/images/email.svg" height={15} className='me-2' alt="phone" />: sales@kailasagroup.com
                                </div>
                                <div className='my-1'>
                                    <img src="/assets/images/location.svg" height={15} className='me-2' alt="phone" />: Kailasa CrestView, B/H Pramukh greens party plot, KH-road, Gandhinagar, Gujarat - 382423
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                )
            }
        },
    ];
    return (
            <Offcanvas show={show} onHide={handleClose}>
                <Offcanvas.Header closeButton>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    {sidebarData.map((item) => {
                        if (item.formatter) {
                            return item.formatter();
                        }
                        return (
                            <div key={item.title} className='my-3 sidebar-item'>
                                <span onClick={() => window.location.href = item.navigationUrl}>{item.title}</span>
                            </div>
                        )
                    })}
                </Offcanvas.Body>
            </Offcanvas>
    )
}
